<template>
    <div class="aniDiv" id="showLocation">
        <div class="aniHead">
        <el-row >
            <el-col :span=18 :offset="6">
<!-- 
                <div class="div1">{{menu.type}}</div>
                <div class="div2">关注酷鹏凯迪最新动态，了解产业智能化最新成果。</div> -->
            </el-col>
        </el-row>            
        </div>
        <div class="aniContent">
            
            <div class="menuDiv">
                <span>新闻动态</span> / <span @click="turntoNews">新闻动态</span> / <span>{{obj.title}}</span>
            </div>
            <div class="aniInfoDiv">
                <h4>{{obj.title}}</h4>
                <div class="dateDiv">{{obj.newDate}}</div>
                <div class="contentDiv">
                   
                    <p v-html="obj.content"></p>
                    <img :src="obj.imgUrl"  />
                </div>
            </div>

        </div>
        
    </div>
</template>
<script>
export default {
    name:"aboutNewsInfo",
    data(){
        return{
            menu:{},
            obj:{},
            list:[
                {
                    id:"n1",
                    date:"20210324",
                    title:"Adobe 携手全球商业领袖成立国际顾问委员会，助力企业在数字经济时代蓬勃发展",
                    content:`
                    中国  北京一2021 年 3 月24 日一随着各行各业都在转变策略以适应数字经济时代的全新规则  Adobe 于近日宣布成立其首个国际顾问委员会，
                    进一步帮助客户进行业务革新并制定数字化转型策略，
                    以在客户体验为主导的新时代中脱颖而出Adobe所成立的国际顾问委员会汇集了六位专注于国际市场的领导者，
                    他们在B2B、B2C 和政府领域的革新和转型中具备非常丰富的领导经验。
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n2",
                    date:"20210122",
                    title:"2021欧特克年度设计峰会线上开启 全方位探索数字技术创新价值",
                    content:`
                    2021年1月22日 – 今天，以 “重塑无限 创想未来” 为主题的 2021欧特克年度设计峰会以数字化的形式成功举办，
                    来自大中华区的工程建设业、制造业、传媒和娱乐业的众多创新者、设计精英和业界专家相聚云端，共同分享数字化转型的现状与机遇，
                    洞察领先技术的应用实践与前景，为线上的近万名观众打造了一场收获颇丰的创新盛宴，
                    勾画出数字未来的美好愿景。
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n3",
                    date:"20210325",
                    title:"Chaos Cloud education packages released",
                    content:`
                    Discounts on Chaos Cloud credits for all educational customers, 
                    plus 10 Chaos Cloud credits included in V-Ray Education Collection student and educator licenses.
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n4",
                    date:"20210324",
                    title:"Minitab 发布 Minitab Engage™ 以加快想法产生、创新和业务转型",
                    content:`
                    2021 年 3 月 25 日，宾夕法尼亚州立大学 – 数据分析、预测分析和过程改进领域的市场领导者 Minitab, LLC 宣布推出 Minitab EngageTM，一款独一无二的解决方案，在设计上，该解决方案能够在从想法产生到执行的过程中启动、跟踪、管理和共享创新和改进计划。
                    Minitab Engage 通过提供用于启动和管理创造价值的项目和创新的独特平台，让组织能够加快数字化转型。
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n5",
                    date:"20210324",
                    title:"ENSCAPE 3.0 PRESS RELEASE",
                    content:`
                    Enscape Launches Redesign of Real-Time Rendering Solution for Architects to Enable Quick and Intuitive Visualisation. 
                    Update optimises design process for architectural and interior designs
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                }

            ]
        }
    },
    methods:{
        init(){
            let id=this.$route.query.id

            for(let i=0;i<this.list.length;i++){
                let item=this.list[i]
                if(item.id==id){
                    item.newDate=item.date.substring(0,4)+"年"+item.date.substring(4,6)+"月"+item.date.substring(6,8)+"日"
                    this.obj=item
                    
                    break
                }
            }
           
            this.$nextTick(function(){
                document.querySelector('#showLocation').scrollIntoView(true);

            })
        },
        turntoNews(){
            let type=this.$route.query.type
            if(type!="index"){
                history.go(-1)
            }else{
                this.$router.push({
                    name:"aboutNews"
                })
            }
            
        }
    },
    created(){
        this.init()
    }
}
</script>
<style>
.aniDiv{
    width:100%
}
.aniHead{
    background-image: url("../../static/aninfo_head_bg.png");
    height:280px
}
.aniHead .div1{
    height: 56px;
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    line-height: 56px;
    margin-top: 90px;
}
.aniHead .div2{
    height: 30px;
    font-size: 22px;
    font-weight: 500;
    color: #f0f0f0;
    line-height: 30px;
    margin-top: 14px;
}
.aniContent{
    width:70%;
     margin:0 auto;
}
.menuDiv{
    padding:40px 0;
    font-size: 15px;
    color: #252C3D;
}
.menuDiv span:nth-child(2){
    color:#3C6AFB;
    cursor:pointer;
}
.menuDiv span:last-child{
    color:#848484;
}
.aniInfoDiv{
    padding:30px 40px;
    box-shadow: 0px 10px 20px 0px #f3f3f3;
    min-height:300px;
}
.aniInfoDiv .dateDiv{
   margin:10px 0;
   color:#848484;
   font-size:14px;
}
.aniInfoDiv .contentDiv{
    color:#848484;
    font-size: 14px;
    line-height:26px;
    margin:25px 0;
}


</style>